<div class="content-wrapper">
  <nz-layout>
    <nz-sider *ngIf="customer?.accepted_tos" [nzCollapsedWidth]="sidebarWidth" nzCollapsible [(nzCollapsed)]="isCollapsed"
              nzWidth="251px" [nzBreakpoint]="'md'" [nzTrigger]="trigger" style="border-right: 1px solid #E3E9EF;">
      <div class="head-sidebar">
        <div class="logo" *ngIf="!mobileQuery.matches">
          <a class="nav-item company-logo" (click)="goHome()" *ngIf="!isCollapsed">
            <img class="nav-item-text company-name" src="../../../assets/logos/blue-logo.png" />
            <nz-divider></nz-divider>
          </a>
          <a class="nav-item company-logo zt-icon" (click)="goHome()" *ngIf="isCollapsed">
            <img class="nav-item-text company-name" src="../../../assets/logos/blue-cloud.png" />
            <nz-divider></nz-divider>
          </a>
        </div>
<!--        <ul class="add-btn-plus" *ngIf="isCollapsed">-->
<!--          <div (click)="createContent()">-->
<!--            <i nz-icon nzType="plus-square" class="side-nav-item add-content-icon"></i>-->
<!--          </div>-->
<!--        </ul>-->
<!--        <ul *ngIf="!isCollapsed" class="m-mobile">-->
<!--          <button nz-button nzType="default" class="create-content-btn" (click)="createContent()">Create Content</button>-->
<!--        </ul>-->
      </div>
      <ul nz-menu nzTheme="light" nzMode="inline" class="menu-list top-menu sidebar-content"
          [nzInlineCollapsed]="isCollapsed" id="style-1">
        <li nz-menu-item *ngFor="let nav of navItems" (click)="goto(nav)"
            [ngClass]="{'selected-item': isSelected(nav),'ant-menu-item-selected': isSelected(nav), 'ant-menu-submenu-title': nav.subNavs}">
<!--          <i nz-icon [nzType]="nav.icon" class="side-nav-item" [ngClass]="{'ant-menu-item-icon-selected': isSelected(nav), 'ant-menu-submenu-title': nav.subNavs}"></i>-->
          <img src="../../../assets/icons/sidebar/{{nav.icon}}.svg"  class="image-sidebar-icon"/>
          <span class="nav-text">{{nav?.title}}</span>
        </li>
        <li nz-submenu *ngIf="resources" class="sub-menu-title" [nzTitle]="resourcestmp">

          <ng-template #resourcestmp>
            <img src="../../../assets/icons/sidebar/{{resources.icon}}.svg" />
            <span class="nav-text" style="padding: 5px;">{{resources.title}}</span>
          </ng-template>
          <ul class="scrollable-list">
            <li nz-menu-item *ngFor="let category of resources.categories" (click)="goto(category)"
                [ngClass]="{'selected-item': isSelected(category),'ant-menu-item-selected': isSelected(category)}">
              <img src="../../../assets/icons/sidebar/{{category.icon}}.svg" />
              <span class="nav-text">{{category?.title}}</span>
            </li>
          </ul>
        </li>
<!--        <li nz-submenu *ngIf="crm" class="sub-menu-title" nzOpen nzIcon="read" nzTitle="CRM">-->
<!--          <ul>-->
<!--            <li nz-menu-item *ngFor="let category of crm.categories" (click)="goto(category)"-->
<!--                [ngClass]="{'selected-item': isSelected(category),'ant-menu-item-selected': isSelected(category)}">-->
<!--              <i nz-icon [nzType]="category.icon" class="side-nav-item"></i>-->
<!--              <span class="nav-text">{{category?.title}}</span>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </li>-->
        <li nz-submenu *ngIf="newsArticle" class="sub-menu-title" nzOpen nzIcon="read" nzTitle="News & Articles">
          <ul class="scrollable-list">
            <li nz-menu-item *ngFor="let category of newsArticle.categories" (click)="goto(category)"
                [ngClass]="{'selected-item': isSelected(category),'ant-menu-item-selected': isSelected(category)}">
              <i nz-icon [nzType]="category.icon" class="side-nav-item"></i>
              <span class="nav-text">{{category?.title}}</span>
            </li>
          </ul>
        </li>
        <li nz-submenu *ngIf="outreach" class="sub-menu-title" nzOpen nzIcon="read" nzTitle="Outreach">
          <ul class="scrollable-list">
            <li nz-menu-item *ngFor="let category of outreach.categories" (click)="goto(category)"
                [ngClass]="{'selected-item': isSelected(category),'ant-menu-item-selected': isSelected(category)}">
              <i nz-icon [nzType]="category.icon" class="side-nav-item"></i>
              <span class="nav-text">{{category?.title}}</span>
            </li>
          </ul>
        </li>
        <nz-badge [nzCount]="iconTemplate" nzStandalone class="new-badge" *ngIf="emailNav && !isCollapsed"></nz-badge>
        <ng-template #iconTemplate>
          New
        </ng-template>
        <li nz-submenu *ngIf="emailNav && !isCollapsed" class="sub-menu-title" nzOpen nzIcon="mail" nzTitle="Email">
          <ul class="scrollable-list">
            <li nz-menu-item *ngFor="let subNav of emailNav.subNavs" (click)="goto(subNav)"
                [ngClass]="{'selected-item': isSelected(subNav),'ant-menu-item-selected': isSelected(subNav)}">
              <i nz-icon [nzType]="subNav.icon" class="side-nav-item"></i>
              <span class="nav-text">{{subNav?.title}}</span>
            </li>
          </ul>
        </li>
      </ul>
      <ng-container>
      </ng-container>
    </nz-sider>
    <nz-layout>
      <nz-header *ngIf="mobileQuery.matches && '/linkedin' != router.url.split('?')[0]">
          <span
            class="trigger"
            nz-icon
            [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
            (click)="isCollapsed = !isCollapsed"
          ></span>
        <div class="logo logo-nav" *ngIf="isCollapsed">
          <a class=" company-logo" (click)="goHome()">
            <img class="nav-item-text company-name" src="../../../assets/logos/blue-logo.png" />
          </a>
        </div>
      </nz-header>
      <nz-header *ngIf="!mobileQuery.matches " nz-row class="align-items-center">
<!--         <span-->
<!--           class="trigger"-->
<!--           nz-icon-->
<!--           [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"-->
<!--           (click)="isCollapsed = !isCollapsed"-->
<!--         ></span>-->
        <!-- <i class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'left'"
          (click)="isCollapsed = !isCollapsed"></i> -->
        <!-- <ng-template #contentTemplate>
          <div>
            <p>Content</p>
            <p>Content</p>
          </div>
        </ng-template>-->
        <div nz-col nzSpan="10">
          <!-- <h1 class="m-0">Welcome!, {{customer?.display_name}}</h1> -->
          <h1 class="m-0 section-title title">
            {{topHeadingWithIcon?.title}}</h1>
          <p *ngIf="!customer.fb_business_url" class="m-0 fb-connect-msg">Before you can leverage the full power of
            Zentap,you'll need to connect your Facebook page</p>
        </div>
        <div nz-col nzSpan="14" class="header-left-pane">
          <button nz-button nzType="default" class="create-content-button" (click)="createContent()">
            <i nz-icon nzType="plus" nzTheme="outline" class="user-menu-icons icon"></i>
            Create Content</button>
          <nav class="tool-navigation" *ngIf="customer">
            <a *ngIf="customer.fb_business_url" class="tool-link" [href]="customer.fb_business_url" target="_blank">
              <img src="../../../assets/icons/social/round/facebook.svg" class="tool-icon" />
            </a>
            <a *ngIf="customer.instagram_url" class="tool-link" [href]="customer.instagram_url" target="_blank">
              <img src="../../../assets/icons/social/round/instagram.svg" class="tool-icon" />
            </a>
            <a *ngIf="customer.linkedin_url" class="tool-link" [href]="customer.linkedin_url" target="_blank">
              <img src="../../../assets/icons/social/round/linkedin.svg" class="tool-icon" />
            </a>
            <a 
              class="social-link" 
              *ngIf="customer.google_business_url || isGoogleAccountConnected"
              (click)="onGoogleIconClick($event)">
              <img src="../../../assets/icons/google.png" class="profile-link" />
            </a>
            <a *ngIf="customer.youtube_url" class="tool-link" [href]="customer.youtube_url" target="_blank">
              <img src="../../../assets/icons/social/round/youtube.svg" class="tool-icon" />
            </a>
            <a *ngIf="customer.tiktok_url" class="tool-link" [href]="customer.tiktok_url" target="_blank">
              <img src="../../../assets/icons/social/round/tiktok.svg" class="tool-icon" />
            </a>
          </nav>


<!--          <div class="profile-social-links" *ngIf="customer">-->
<!--            &lt;!&ndash;            <span *ngIf="!customer.fb_business_url" (click)="connectFacebook()" class="social-link fb-connect"  target="_blank">&ndash;&gt;-->
<!--            &lt;!&ndash;              <img src="../../../assets//icons/facebook.png" class="profile-link" />&ndash;&gt;-->
<!--            &lt;!&ndash;              Connect Facebook&ndash;&gt;-->
<!--            &lt;!&ndash;            </span>&ndash;&gt;-->
<!--            <a *ngIf="customer.fb_business_url" class="social-link" [href]="customer.fb_business_url" target="_blank">-->
<!--              <img src="../../../assets//icons/facebook.png" class="profile-link" />-->
<!--            </a>-->
<!--            <a *ngIf="customer.instagram_url" class="social-link" [href]="customer.instagram_url" target="_blank">-->
<!--              <img src="../../../assets//icons/instagram.png" class="profile-link" />-->
<!--            </a>-->
<!--            <a *ngIf="customer.linkedin_url" class="social-link" [href]="customer.linkedin_url" target="_blank">-->
<!--              <img src="../../../assets//icons/linkedin.png" class="profile-link" />-->
<!--            </a>-->
<!--            <a *ngIf="customer.google_business_url" class="social-link" [href]="customer.google_business_url"-->
<!--               target="_blank">-->
<!--              <img src="../../../assets//icons/google.png" class="profile-link" />-->
<!--            </a>-->
<!--            <a *ngIf="customer.youtube_url" class="social-link" [href]="customer.youtube_url" target="_blank">-->
<!--              <img src="../../../assets//icons/youtube.png" class="profile-link-yt" />-->
<!--            </a>-->
<!--            <a *ngIf="customer.tiktok_url" class="social-link" [href]="customer.tiktok_url" target="_blank">-->
<!--              <img src="../../../assets//icons/tiktok.png" class="profile-link-yt" />-->
<!--            </a>-->
<!--          </div>-->
          <app-notification-tab></app-notification-tab>
          <div class="avatar-wrapper"
               nz-button
               nz-popover
               [nzPopoverContent]="userMenu"
               nzPopoverPlacement="bottomRight"
               [(nzPopoverVisible)]="visible"
               nzPopoverTrigger="click">
            <nz-avatar *ngIf="customer?.image"
                       class="user-icon-button mat-elevation-z8"
                       nzIcon="user"
                       [nzSrc]="customer?.image?.thumb">
            </nz-avatar>
<!--            <span>{{ customer?.display_name }} </span>-->
<!--            <i nz-icon nzType="down" class="dropmenu-icon" nzTheme="outline"></i>-->
          </div>
          <ng-template #userMenu>
            <div class="user-menu">
              <p class="user-menu-item" *ngFor="let item of profileMenuItems" (click)="handleMenuAction(item.action)">
                <i nz-icon [nzType]="item.icon" nzTheme="outline" class="user-menu-icons"></i>
                {{item.text}}
              </p>
            </div>
          </ng-template>
        </div>
      </nz-header>
      <!--    <app-bread-crum class="full-width" *ngIf=" '/welcome' !== router.url"></app-bread-crum>-->
      <nz-content class="cotent-container">
        <div class="main-container">
          <nz-layout class="mb-50px">
            <nz-content>
              <nz-alert nzType="warning" [nzMessage]="messageTemplate" style="margin-bottom: 5px"
                        *ngIf="customer.render_only && customer.account_name=='HOI'"></nz-alert>
              <ng-template #messageTemplate>
                <p>You’re current plan only allows you to create content, click <a
                  href="https://calendly.com/homes-of-idaho-onboarding/homes-of-idaho-onboarding?month=2023-04"
                  style="padding: 0px">HERE</a> to get setup with automated posting </p>
              </ng-template>
              <ng-content></ng-content>
            </nz-content>
          </nz-layout>
        </div>
      </nz-content>
    </nz-layout>
  </nz-layout>
<ng-template #trigger>
  <div>
    <span
      class="trigger"
      nz-icon
      (click)="$event.stopPropagation(); toggleCollapse()"
      [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
    ></span>
  </div>
</ng-template>

</div>

<!-- <app-sidebar> -->
<ng-template #contentrequestform>
  <div class="container">
    <h1 *ngIf="product" class="title">{{isNew ? 'New' : 'Update'}} {{isTeamCoverPhotos ? 'Team ' : ''}}
      {{product.normalizedName}} {{isCommercialListingVideo
      && current > 0 ? pickedProperty : '' }}</h1>
    <nz-steps [nzCurrent]="current" *ngIf="content.category !== 'MV' && !isTeamCoverPhotos" class="content-stepper">
      <nz-step nzTitle="Pick Property Type" *ngIf="isCommercialListingVideo" (click)="gotoStep(0)"></nz-step>
      <nz-step nzTitle="Pick a Type" *ngIf="content.contentable_type === 'MarketReport'" (click)="gotoStep(0)">
      </nz-step>
      <nz-step nzTitle="Edit Details"
        *ngIf="!(content.contentable_type === 'MarketReport') && !isCoverPhotos && !isBannerVideo"
        (click)="gotoStep(editDetailStep)"></nz-step>
      <nz-step *ngIf="!selectedStyle" nzTitle="Customize Content" (click)="gotoStep(customizeContentStep)"></nz-step>
      <nz-step nzTitle="Done"></nz-step>
    </nz-steps>
    <!-- <div class="steps-content"
        *ngIf="(current === 0 && !firstStepHideCreate)  && (current === 0 && !isSingleSnapShot && content.contentable_type !== 'MarketReport' && !isCommercialListingVideo ) || (current === 1 && !isSingleSnapShot && !isCommercialListingVideo) || (current === 1 && isSingleSnapShot) || (current === 2 && isCommercialListingVideo)">
        <div nz-row class="top-actions">
          <div nz-col nzSpan="8">
            <button nz-button nzSpan="12" *ngIf="!loading" class="back-btn"
              (click)="pre(current === 0 && !isSingleSnapShot && content.contentable_type === 'MarketReport');">
              Back</button>
          </div>
          <div nz-col nzSpan="8" nzOffset="8">
            <button nz-button nzSpan="12" class="fl-r" nzType="primary" color="primary" *ngIf="!loading"
              [disabled]="!content.style" (click)="finalSubmit()">Create</button>
          </div>
        </div>
      </div> -->

    <div class="steps-content" *ngIf="current === 0 && isSingleSnapShot">
      <app-single-data-snapshot-form *ngIf="type === 'Content'" [contentData]="content"
        (change)="onChangeSnapType($event)" (zeroValuesChange)="onChangeZeroValues($event)"
        (zipDataChange)="onChangeZipData($event)" (propertyTypeChange)="onPropertyTypeChange($event)"
        [model]="contentSubject"></app-single-data-snapshot-form>
      <div [ngStyle]="{'width': '100%', 'margin-top': '16px'}" nz-row nzType="flex" nzJustify="end">
        <button nz-button nzType="primary" *ngIf="!loading && 7 > zeroValueCount"
          [disabled]="!content.extra_attributes  || !content.extra_attributes.data_points || !content.extra_attributes.data_points.length"
          (click)="saveSnapShot()">Save and Continue</button>
      </div>
    </div>


    <div class="steps-content" *ngIf="current === 0 && isCommercialListingVideo">
      <app-commercial-property-type-selector (change)="onChangePropertyType($event)" [model]="contentSubject">
      </app-commercial-property-type-selector>
      <div [style.width]="'100%'" nz-row nzType="flex" nzJustify="end">
        <button nz-button nzType="primary" [disabled]="!selectedProperty" (click)="saveSnapShot()">Next</button>
      </div>
    </div>


    <div class="steps-content"
      *ngIf="!isCoverPhotos && !isBannerVideo && (current === 0 && content.category !== 'MV' && !isSingleSnapShot && content.contentable_type !== 'MarketReport' && !isCommercialListingVideo) || (current === 1 && isCommercialListingVideo)">
      <ng-template matStepperIcon="done">
        <!-- <mat-icon>done_all</mat-icon> -->
        <i nz-icon nzType="check" nzTheme="outline"></i>
      </ng-template>
      <!-- <ng-template matStepLabel>Edit Details</ng-template> -->
      <app-customer-form #rForm *ngIf="type === 'Customer'" [requiredFields]="requiredFields" [showFields]="showFields"
        [limitShow]="true" [model]="contentSubject"></app-customer-form>
      <app-listing-form #rForm *ngIf="type === 'Listing'" (requiredChange)="updateRequiredFields($event)"
        [requiredFields]="allReqFields" [extraFields]="showFields" [limitShow]="true" [model]="contentSubject"
        [property]="selectedProperty"></app-listing-form>
      <app-market-report-form #rForm *ngIf="type === 'Content'" [model]="contentSubject"
        [requiredFields]="requiredFields" [extraFields]="showFields" [limitShow]="true">
      </app-market-report-form>
      <div [style.width]="'100%'" nz-row nzJustify="space-between">
        <button nz-button *ngIf="isSingleSnapShot || isCommercialListingVideo" class="back-btn"
          (click)="pre();">Back</button>
        <div *ngIf="!(isSingleSnapShot || isCommercialListingVideo)"></div>
        <button class="right-btn" nz-button nzType="primary" [disabled]="imageLoadEnd" *ngIf="!loading"
          (click)="saveResource()">Save and Continue</button>
      </div>
    </div>


    <div class="steps-content"
      *ngIf="current === 0 && content.contentable_type === 'MarketReport' && !isSingleSnapShot && !isCommercialListingVideo">
      <app-market-report-data-selector (selectedDataChange)="onChangeDataPoints($event)"
        (zipDataChange)="onChangeZipData($event)" (zeroValuesChange)="onChangeZeroValues($event)"
        (propertyTypeChange)="onPropertyTypeChange($event)" [model]="contentSubject"></app-market-report-data-selector>
       <div [style.width]="'100%'" nz-row nzType="flex" nzJustify="end" class="mrt-16">
        <button nz-button nzType="primary" *ngIf="!loading && 7 > zeroValueCount"
          [disabled]="!content.extra_attributes || !content.extra_attributes.data_points || !content.extra_attributes.data_points.length"
          (click)="delayedNext()">Save and Continue</button>
      </div>
    </div>


    <div *ngIf="!selectedStyle">
      <div class="steps-content"
        *ngIf="(current === 0 && (isCoverPhotos || isBannerVideo))||(current === 1 && !isSingleSnapShot && content.contentable_type === 'MarketReport' && !isCommercialListingVideo) || (current === 1 && !isSingleSnapShot && !isCommercialListingVideo) || (current === 1 && isSingleSnapShot) || (current === 2 && isCommercialListingVideo)">
        <app-customer-form #rForm *ngIf="type === 'Customer' && isBannerVideo" [requiredFields]="requiredFields"
          [showFields]="showFields" [limitShow]="true" [model]="contentSubject"></app-customer-form>
        <h1 class="section-title section-title-small" *ngIf="isBannerVideo || isCoverPhotos">
          {{ isBannerVideo ? 'Select Style' : 'Select Category' }}
        </h1>
        <app-content-form [submitted]="submitted" *ngIf="styles && !isCoverPhotos"
          (changeStyle)="selectTemplate($event)" #contentForm [model]="content" (nextPage)="nextPage()"
          (hashTagLimit)="hashTagLimit($event)" [styles]="styles"
          [showCaption]="!(isBannerVideo || isCoverPhotos || isWebsite)" [isCoverPhotos]="isCoverPhotos"
          [socialConfig]="socialConfig" [showSchedule]="showSchedule" [isFlyer]="isListingFlyers || isCommercialListingFlyers" [showColors]="!(isMarketVideo || isWebsite)">
        </app-content-form>
        <app-content-form [submitted]="submitted" *ngIf="isCoverPhotos && stockImages" #contentForm2 [model]="content"
          [stockImages]="stockImages" (nextPage)="nextPage()" (nextCoverPhotoPage)="getStockImages($event)" (categoryChange)="onCategoryChange($event)"
          [coverPhotoCategory]="coverPhotoCategory"
          (hashTagLimit)="hashTagLimit($event)" [styles]="styles" [isCoverPhotos]='isCoverPhotos'
          [showCaption]="!(isBannerVideo || isCoverPhotos)" [isFlyer]="isListingFlyers || isCommercialListingFlyers" [socialConfig]="socialConfig" [showSchedule]="showSchedule"
          [showColors]="!isMarketVideo">
        </app-content-form>
      </div>

      <div class="steps-content"
        *ngIf="(current === 2 && !isSingleSnapShot && !isCommercialListingVideo) || (current === 3 && (isSingleSnapShot || isCommercialListingVideo))">
        <h3>Success!</h3>
        <p>Redirecting you back.</p>
        <div class="spinner" *ngIf="loading">
          <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
        </div>
        <!-- <mat-progress-spinner *ngIf="loading" mode="indeterminate" diameter="120" class="spinner"></mat-progress-spinner> -->
      </div>
      <div class="steps-content bottom actions"
        *ngIf="
        (
          current === 0 &&
          !isSingleSnapShot &&
          content.contentable_type !== 'MarketReport' &&
          !isCommercialListingVideo &&
          !isInstagramFlyer
        ) &&
        (current === 0 && !firstStepHideCreate) &&
        (current === 0 && !isFacebookAds) &&
        (current === 0 && !isLocalMarketVideo) &&
        (current === 0 && !isSocialMediaPosts) ||
        (current === 1 && !isSingleSnapShot && !isCommercialListingVideo) ||
        (current === 1 && isSingleSnapShot) ||
        (current === 2 && isCommercialListingVideo)
      ">
        <div>
          <!-- this.product: {{product.name }} {{isLocalMarketVideo}} -->
        </div>
        <div nz-row nzJustify="space-around" class="end-actions">
          <div nz-col nzSpan="8">
            <button nz-button nzSpan="12" class="back-btn" *ngIf="!isCoverPhotos && !isBannerVideo"
              (click)="pre(current === 0 && !isSingleSnapShot && content.contentable_type === 'MarketReport');">
              Back</button>
          </div>
          <div nz-col nzSpan="4" *ngIf="showSchedule" nzOffset="8" class="text-right">
            <button nz-button nzSpan="12" (click)="showSchedule = false;content.scheduled_for = null">
              Cancel Schedule</button>
          </div>
          <div *ngIf="showSchedule" nz-col nzSpan="4">
            <button nz-button nzSpan="12" class="fl-r"
              [disabled]="(isCoverPhotos && content.extra_attributes && !content.extra_attributes.background_photo)"
              nzType="primary" color="primary" *ngIf="!loading" (click)="finalSubmit()">Schedule Post</button>
          </div>
          <div *ngIf="!showSchedule && !isWebsite" nz-col nzSpan="8" nzOffset="8">
            <button nz-button nz-dropdown nzType="primary"
              [disabled]="(isCoverPhotos && content.extra_attributes && !content.extra_attributes.background_photo) || ishashTagLimit"
              [nzDropdownMenu]="menu4" class="fl-r" *ngIf="!loading">
              Create
              <i nz-icon nzType="down"></i>
            </button>
            <nz-dropdown-menu #menu4="nzDropdownMenu">
              <ul nz-menu *ngIf="!ishashTagLimit">
                <li nz-menu-item (click)="render()">
                  <i nz-icon nzType="sketch"></i>
                  Render
                </li>
                <li nz-menu-item (click)="showSchedule = true">
                  <i nz-icon nzType="field-time" nzTheme="outline"></i>
                  Schedule
                </li>
                <li nz-menu-item (click)="finalSubmit()">
                  <i nz-icon nzType="share-alt"
                    [ngStyle]="{'color': content.status === 'ready' ? '#0170f7' : '#9d9a9a'}" class="down-icon"></i>
                  Post Now
                </li>
              </ul>
            </nz-dropdown-menu>
          </div>
          <div *ngIf="isWebsite" nz-col nzSpan="8" nzOffset="8">
            <button nz-button nzType="primary" [disabled]="!content.style " (click)="createListingSite()" class="fl-r"
              *ngIf="!loading">
              Create
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<nz-spin [nzSize]="'large'" *ngIf="loading; else notloading">
  <ng-container [ngTemplateOutlet]="contentrequestform"></ng-container>
</nz-spin>
<ng-template #notloading>
  <ng-container [ngTemplateOutlet]="contentrequestform"></ng-container>
</ng-template>
<!-- </app-sidebar> -->

import { Component, OnInit, ViewChild, TemplateRef, Input } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Location } from '@angular/common';
import { NgForm } from '@angular/forms';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

import { Customer } from '../../vos/customer/customer';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { SettingsService } from '../../services/settings/settings.service';
import { Setting } from '../../vos/setting/setting';
import { BreadCrumService } from '../../services/breadcrum/bread-crum.service';
import { ConnectLinkedinService } from '../../services/connect-linkedin/connect-linkedin.service';
import { CustomersService } from '../../services/customers/customers.service';
import { SocialPostDialogComponent } from '../../shared/social-post-dialog/social-post-dialog.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UpgradeDialogComponent } from '../../shared/upgrade-dialog/upgrade-dialog.component';
import { CustomerFormComponent } from '../../shared/customer-form/customer-form.component';
import { BrokerageService } from '../../services/brokerage/brokerage.service';
import { CustomersFacebookService } from '../../services/customers-facebook/customers-facebook.service';
import { CustomersGoogleService } from '../../services/customers-google/customers-google.service';
import { CustomersYoutubeService } from '../../services/customers-youtube/customers-youtube.service';
import { ConnectInstagramService } from '../../services/connect-instagram/connect-instagram.service';
import { FacebookOnboardingDialogComponent } from '../../shared/facebook-onboarding-dialog/facebook-onboarding-dialog.component';
import { GoogleOnboardingDialogComponent } from '../../shared/google-onboarding-dialog/google-onboarding-dialog.component';
import { ManualFacebookService } from '../../services/manual-facebook/manual-facebook.service';
import { GlobalsService } from '../../services/globals/globals.service';
import { ProductsService } from '../../services/products/product-api.service';
import {YoutubeOnboardingDialogComponent} from '../../shared/youtube-onboarding-dialog/youtube-onboarding-dialog.component';
import {GenericDialogComponent} from '../../shared/generic-dialog/generic-dialog.component';
import {CustomersTiktokService} from '../../services/customers-tiktok/customers-tiktok.service';
import {TiktokOnboardingDialogComponent} from '../../shared/tiktok-onboarding-dialog/tiktok-onboarding-dialog.component';
import {BillingService} from '../../services/billing/billing.service';
import {QrcodeService} from '../../services/qrcode/qrcode.service';
import {DownloaderService} from '../../services/downloader.service';
import * as FileSaver from 'file-saver';
import {HttpClient} from '@angular/common/http';
import {SociallinksService} from '../../services/social-links/social-links.service';
import {NpsScoreDialogComponent} from '../../shared/nps-score-dialog/nps-score-dialog.component';
import {SocialLinksDialogComponent} from '../../shared/social-links-dialog/social-links-dialog.component';
import {IntegrationsService} from '../../services/integrations/integrations.service';
import { IModalData } from 'src/app/models/interfaces/modals/modal';
import { GenericDialogConfig } from 'src/app/models/interfaces/modals/generic-modal-config';
import {StylesService} from '../../services/styles/styles.service';
import {Content} from '../../vos/content/content';
import {CustomerProductStylesService} from '../../services/customer-product-styles/customer-product-styles.service';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})

export class SettingsComponent implements OnInit {
  @ViewChild('statusTmp') template: TemplateRef<any>;
  @ViewChild('customerForm') customerForm: CustomerFormComponent;
  @ViewChild('realtorForm') realtorForm: NgForm;

  valid = false;
  loadingIGName = false;
  loading = false;
  settings: any;
  brokerageList = [];
  dataPointsTitle;
  contentSubscription;
  postFailed;
  marketReportSubscription;
  roiSubscription;
  selectedMenuItem;
  filteredSettings = [];
  selectedDataPoints = [];
  nonSelectedDataPoints = [];
  selectedMenu = 'account_details';
  mlsRealtor = {
    license_number: '',
    brokerage: ''
  };
  mlsRealtorClone = {
    license_number: '',
    brokerage: ''
  };
  brokerageShowFields = ['brokerage', 'license_number'];
  showFields = [
    'first_name',
    'last_name',
    'email',
    'primary_phone',
    'primary_city',
    'primary_state',
    'primary_zip'
  ];
  toolTips = {
    'website.create.later': 'Notifies you whenever we make an agent website or single property website for you.',
    'listing.create.later': 'We will notify you whenever a new listing on your account is created.',
    'lead.create.later': 'We will notify you whenever a new lead from our services comes in.',
    'integration.update.mls_status.later': 'Notifies you if your MLS connection is approved or denied.'
  };
  sideMenuList = [
    {
      title: 'Account Details',
      key: 'account_details',
    },
    {
      title: 'MLS Settings',
      key: 'MLS_settings',
    },
    {
      title: 'Content and Posting',
      key: 'content_posting',
    },
    {
      title: 'Styles',
      key: 'styles',
    },
    {
      title: 'Website',
      key: 'website'
    },
    {
      title: 'Listing',
      key: 'listing'
    },
    {
      title: 'Lead',
      key: 'lead'
    },
    // {
    //   title: 'Integration',
    //   key: 'integration'
    // },
    {
      title: 'Data Points',
      key: 'data_points'
    },
    {
      title: 'Captions',
      key: 'captions'
    },
    {
      title: 'Team Settings',
      key: 'team_settings'
    },
    {
      title: 'Billing',
      key: 'billing'
    },
    {
      title: 'Zentap Card',
      key: 'zentap_card'
    }
  ];
  digest_settings: any;
  @Input()
  likedinConnectedSuccess;
  customer: Customer;
  //linkedIn integration
  integration;

  instagramIntegration;
  facebookPageIntegration;
  manualFacebookIntegration;
  manualFacebookIntegrationStatus = false;
  googlePageIntegration;

  linkedinStatus = false;
  linkedinAutopost = false;
  instagramStatus = false;
  instagramAutopost = false;

  manualFacebookAutopost = false;
  integrationdId;
  paidTier = false;
  isFacebookPageConnected = false;
  facebookPageAutopost = false;
  facebookConnectedPage;
  facebookConnectedPageStatus = false;

  isGoogleAccountConnected = false;
  googleBusinessAutopost = false;
  googleConnectedPage;
  googleConnectedMydBusinessStatus = false;


  isYoutubeConnected = false;
  youtubeIntegration;
  youtubeAutopost = false;
  youtubeConnectedPage;
  youtubeConnectedStatus = false;
  youtubeStatus = false;


  isTiktokConnected = false;
  tiktokIntegration;
  tiktokAutopost = false;
  tiktokConnectedStatus = false;
  tiktokStatus = false;

  tempAutopostState: { [key: string]: boolean } = {};

  yes = true;
  no = false;
  params: any;
  dataPoints = [];
  dataPointsId;
  dataPointKeys = [];
  session_url;
  slug;
  qrCodeImage;
  sociallinks;
  filteredStyles;
  constructor(
    private subscriptionService: SettingsService,
    private authService: AuthenticationService,
    private customerService: CustomersService,
    private message: NzNotificationService,
    private messageService: NzMessageService,
    private location: Location,
    private breadcrumService: BreadCrumService,
    private linkedInService: ConnectLinkedinService,
    private instagramService: ConnectInstagramService,
    private manualFacebookService: ManualFacebookService,
    private modalService: NzModalService,
    private router: Router,
    private route: ActivatedRoute,
    private brokerageService: BrokerageService,
    private customersFacebookService: CustomersFacebookService,
    private customersGoogleService: CustomersGoogleService,
    private customersYoutubeService: CustomersYoutubeService,
    private customersTiktokService: CustomersTiktokService,
    private notification: NzNotificationService,
    private globalsService: GlobalsService,
    private productsService: ProductsService,
    private billingService: BillingService,
    private qrcodeService: QrcodeService,
    private sociallinksService: SociallinksService,
    private downloaderService: DownloaderService,
    private integrationsService: IntegrationsService,
    private stylesService: StylesService,
    private customerProductStylesService: CustomerProductStylesService,
    protected http: HttpClient
  ) { }
  async ngOnInit() {
    this.fetchBrokreage();
    // this.authService.refresh()
    // .subscribe(c => {
    //   this.customer = c;
    // });
    this.route.params.subscribe(params => {
      if (params.type) {
        this.selectedMenu = params.type;
        this.selectedMenuItem = this.sideMenuList?.find((x) => x.key === params.type);
        if (!this.selectedMenuItem) {
          this.router.navigateByUrl('/404');
        }
      }
    });
    this.selectedMenuItem = this.selectedMenuItem || this.sideMenuList[0];
    this.fetch_digest_subscriptions();
    this.fetch_subscriptions();
    this.fetch_content_posting_settings();
    this.fetch_linkedin_autopost_settings();
    this.fetch_manual_fb_autopost_settings();
    this.fetch_instagram_autopost_settings();
    this.fetch_tier();
    this.breadcrumService.set_breadcrum();
    this.fetchDataPoints();
    await this.fetchCustomerProducts();
    this.fetchBillingSession();
    this.getSocialLinks();
    this.checkCustomerConectedFacebookPage();
    this.checkCustomerConectedGoogleBusiness();
    this.checkCustomerConectedYoutube();
    this.checkCustomerConectedTiktok();
  }

  fetchBillingSession(){
    this.billingService.getSessionDetails().subscribe(response => {
      this.session_url = response["session_url"];
    });
  }

  fetchBrokreage() {
    this.brokerageService.brokerageList()
      .subscribe(res => {
        this.brokerageList = res ? res?.data : [];
      }, err => {
        console.error(err);
      });
  }
  checkCustomerConectedFacebookPage() {
    this.customersFacebookService.getFacebookUserConnection()
      .subscribe(res => {
        if (res && res?.body?.data?.length > 0) {
          this.facebookPageIntegration = res.body.data[0];
          // this.facebookPageAutopost = this.facebookPageIntegration.auto_posting;
          this.isFacebookPageConnected = true;
        } else {
          this.isFacebookPageConnected = false;
        }
      });
    this.customersFacebookService.getCustomerConectedFacebookPage()
      .subscribe(res => {
        if (res && res?.body?.data?.length > 0) {
          this.facebookConnectedPage = res.body.data[0];
          this.facebookConnectedPageStatus = true;
          this.facebookPageAutopost = this.facebookConnectedPage.auto_posting;
        }
      });
  }


  checkCustomerConectedGoogleBusiness() {
    this.customersGoogleService.getGoogleUserConnection()
      .subscribe(res => {
        if (res && res?.body?.data?.length > 0) {
          this.googlePageIntegration = res.body.data[0];
          this.isGoogleAccountConnected = true;
          this.googleBusinessAutopost = this.googlePageIntegration.auto_posting;
        } else {
          this.isGoogleAccountConnected = false;
        }
      });
  }
  checkCustomerConectedYoutube() {
    this.customersYoutubeService.getYoutubeUserConnection()
      .subscribe(res => {
        if (res.length > 0) {
          this.youtubeIntegration = res[0];
          this.isYoutubeConnected = true;
          this.youtubeAutopost = this.youtubeIntegration.auto_posting;

          if (!this.youtubeIntegration.user_id_on_provider) {
            this.notifyYoutubeChannelNotFound();
          } else if (this.youtubeStatus && !this.youtubeIntegration.is_youtube_message_show) {
            this.messageService.create('success', 'YouTube connected successfully.');
            this.markYoutubeMessageAsShown();
          }
        } else {
          this.isYoutubeConnected = false;
        }
      });
  }

  notifyYoutubeChannelNotFound() {
    this.messageService.create('warning', 'YouTube connected, but no channel found. Posting is unavailable until a channel is created.', { nzDuration: 5000 });
  }

  checkCustomerConectedTiktok() {
    this.customersTiktokService.getTiktokUserConnection()
      .subscribe(res => {
        if (res.length > 0) {
          this.tiktokIntegration = res[0];
          this.isTiktokConnected = true;
          this.tiktokAutopost = this.tiktokIntegration.auto_posting;
        } else {
          this.isTiktokConnected = false;
        }
      });
  }


  fetchDataPoints() {
    this.loading = true;
    this.customerService.dataPoints()
      .subscribe(res => {
        if (res && res.defaults) {
          this.dataPoints = res.defaults;
          this.dataPointsId = res?.id;
          this.dataPointKeys = Object.keys(this.dataPoints);
          this.dataPointsTitle = {};
          this.dataPointKeys.forEach(key => {
            this.dataPointsTitle[key] = this.dataPoints[key]?.title;
          });
          this.selectedDataPoints = this.dataPointKeys?.filter((key) => this.dataPoints[key].selected);
          this.nonSelectedDataPoints = this.dataPointKeys?.filter((key) => !this.dataPoints[key].selected);
        }
        this.loading = false;
      });
  }

  fetch_subscriptions() {
    this.subscriptionService.subscriptionsList().subscribe(setting => {
      this.settings = setting.subscriptions;
      this.contentSubscription = this.settings?.find(s => s.key === 'content.update.later');
      this.marketReportSubscription = this.settings?.find(s => s.key === 'content.market_report.later');
      this.postFailed = this.settings?.find(s => s.key === 'post.failed');
      this.roiSubscription = this.settings?.find(s => s.key === 'customer.roi.insights');
      this.settings?.map(s => {
        this.getSelectedItem(s.key) === 'content';
      });
    });
  }
  fetch_digest_subscriptions() {
    this.subscriptionService.settingList().subscribe(setting => {
      this.digest_settings = setting.settings;
    });
  }
  fetch_content_posting_settings() {
    this.loading = true;
    this.authService.currentCustomer.subscribe((c) => {
      if (c && c.settings['show_price'] === undefined) {
        c.settings['show_price'] = true;
      }
      if (c && this.mlsRealtor) {
        this.customer = c;
        this.mlsRealtor.license_number = this.customer?.license_number;
        this.mlsRealtor.brokerage = this.customer.brokerage;
        this.mlsRealtorClone = { ...this.mlsRealtor };
      }
      this.loading = false;
    });
  }

  fetch_linkedin_autopost_settings() {
    this.customerService.getLinkedin().subscribe((c) => {
      if (c && c.length > 0 && c[0] && c[0].refresh_token_expires_at) {
        const now = new Date(); /* midnight in China on April 13th */
        now.toLocaleString('en-US', { timeZone: 'America/New_York' });
        const refreshExpireAt = new Date(c[0].refresh_token_expires_at);
        refreshExpireAt.toLocaleString('en-US', { timeZone: 'America/New_York' });

        if (now < refreshExpireAt) {
          // this.linkedinStatus = true;
        }
        this.linkedInService.getAutopost().subscribe(int => {
          this.integration = int && int?.data && int?.data.length ? int?.data[0] : null;
          this.linkedinAutopost = this.integration.auto_posting;
        });
      }
    });
  }
  expired(integration, linkedin = false) {
    if (integration) {
      const now = new Date(); /* midnight in China on April 13th */
      now.toLocaleString('en-US', { timeZone: 'America/New_York' });
      let refreshExpireAt = new Date(integration.expires_at);
      if (linkedin) {
        refreshExpireAt = new Date(integration.refresh_token_expires_at);
      }
      refreshExpireAt.toLocaleString('en-US', { timeZone: 'America/New_York' });
      if (now < refreshExpireAt) {
        // @ts-ignore
        const diffTime = Math.abs(refreshExpireAt - now);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return `Expires in ${diffDays} ${diffDays > 1 ? 'days' : 'day'}`;
      } else {
        return 'Expired';
      }
    }
  }
  fetch_instagram_autopost_settings() {
    this.customerService.getInstagram().subscribe((c) => {
      if (c.length > 0) {
        // this.instagramStatus = true;
        this.instagramService.getAutopost().subscribe(int => {
          this.instagramIntegration = int ? int.data[0] : null;
          this.instagramAutopost = this.instagramIntegration.auto_posting;
        });
      }
    });
  }
  fetch_manual_fb_autopost_settings() {
    this.customerService.getManualFacebook().subscribe((c) => {
      if (c.length > 0) {
        this.manualFacebookIntegrationStatus = true;
        this.manualFacebookIntegration = c[0];
        this.manualFacebookAutopost = this.manualFacebookIntegration.auto_posting;
      }
    });
  }
  subscription_title(title: string) {
    const s_title = title.split('.');
    const sub_title = s_title.slice(Math.max(s_title.length - 2, 1));
    return sub_title.join(' ');
  }

  getSelectedItem(title: string) {
    const s_title = title.split('.');
    return s_title[0];
  }
  get_title(title: string) {
    const titelArray = title.split('.');
    let finalTitle;
    const ignoredStrings = ['later'];
    titelArray.forEach(s => {
      if (ignoredStrings.indexOf(s) === -1) {
        finalTitle = finalTitle ? finalTitle + ' ' + s : s;
      }

    });
    if (title.indexOf('integration') > -1) {
      finalTitle = 'Update status on mls status';
    }
    return finalTitle;
  }
  toggleNotificationSettings(setting: Setting, type?: string) {
    let endpoint;
    let subscribe;
    if (!setting) {
      return;
    }
    if (type === 'email') {
      endpoint = setting?.subscribing_to_email ? 'subscribe_to_email' : 'unsubscribe_to_email';
      subscribe = setting?.subscribing_to_email;
      this.updateSubscriptions(setting?.id, endpoint);
    }
    if (type === 'sms') {
      endpoint = setting?.optional_targets.sms.subscribing ? 'subscribe_to_optional_target' : 'unsubscribe_to_optional_target';
      subscribe = setting?.optional_targets.sms.subscribing;
      this.params = { optional_target_name: 'sms' };
      this.updateSubscriptions(setting?.id, endpoint, this.params);
    }
    if (type === 'subscription') {
      endpoint = setting.subscribing ? 'subscribe' : 'unsubscribe';
      subscribe = setting.subscribing;
      setting.subscribing_to_email = setting?.subscribing ? setting?.subscribing_to_email : false;
      if (setting.optional_targets && setting.optional_targets.sms) {
        setting.optional_targets.sms.subscribing = setting.subscribing ? setting.optional_targets.sms.subscribing : false;
      }
      this.updateSubscriptions(setting?.id, endpoint);
    }
    const message = subscribe ? 'Subscribed' : 'Unsubscribed';
    this.notify_message(message);
  }
  updateSubscriptions(setting_id, endpoint, params?) {
    this.subscriptionService.update_subscription(setting_id, endpoint, params).subscribe(resp => { });
  }
  toggleLinkedInSetting(event?: any) {
    const message = event ? 'Subscribed' : 'Unsubscribed';
    const params = {
      integration: {
        auto_posting: event
      }
    };
    if (this.integration) {
      this.linkedInService.setAutopost(params, this.integration).subscribe(integration => {
      });
    }
  }
  toggleManualFacebookSetting(event?: any) {
    const message = event ? 'Subscribed' : 'Unsubscribed';
    const params = {
      integration: {
        auto_posting: event
      }
    };
    if (this.manualFacebookIntegration) {
      this.manualFacebookService.setAutopost(params, this.manualFacebookIntegration).subscribe(integration => {
      });
    }
  }


  toggleGoogleMyBusinessSetting(event?: any) {
    const message = event ? 'Subscribed' : 'Unsubscribed';
    const params = {
      integration: {
        auto_posting: event
      }
    };
    if (this.googlePageIntegration) {
      this.customersGoogleService.setAutopost(params, this.googlePageIntegration).subscribe(integration => {
      });
    }
  }
  toggleYoutubeSetting(event?: any) {
    const message = event ? 'Subscribed' : 'Unsubscribed';
    const params = {
      integration: {
        auto_posting: event
      }
    };
    if (this.youtubeIntegration) {
      this.customersYoutubeService.setAutopost(params, this.youtubeIntegration).subscribe(integration => {
      });
    }
  }
  toggleTiktokSetting(event?: any) {
    const message = event ? 'Subscribed' : 'Unsubscribed';
    const params = {
      integration: {
        auto_posting: event
      }
    };
    if (this.tiktokIntegration) {
      this.customersTiktokService.setAutopost(params, this.tiktokIntegration).subscribe(integration => {
      });
    }
  }

  toggleInstagramSetting(event?: any) {
    const message = event ? 'Subscribed' : 'Unsubscribed';
    const params = {
      integration: {
        auto_posting: event
      }
    };
    if (this.instagramIntegration) {
      this.instagramService.setAutopost(params, this.instagramIntegration).subscribe(integration => {
      });
    }
  }
  toggleFacebookPageSetting(event?: any) {
    const params = {
      auto_posting: event
    };
    this.customersFacebookService.updateFacebookPageConnection(params, this.facebookConnectedPage.id)
      .subscribe(res => {
        if (res && res?.body?.data?.length > 0) {
          this.facebookPageAutopost = res.body.data[0].auto_posting;
        }
      });
  }
  toggleSetting(setting?: string, event?: any) {
    const message = event ? 'Subscribed' : 'Unsubscribed';
    this.customerService.update(this.customer).subscribe((c) => {
      this.customer = c;
    });
  }
  toggleDigestSetting(setting: Setting) {
    const endpoint = setting?.status ? 'subscribe' : 'unsubscribe';
    const message = setting?.status ? 'Subscribed' : 'Unsubscribed';
    this.subscriptionService.update_digest_subscription(setting?.id, endpoint).subscribe(resp => {
      this.notify_message(message);
    });
  }
  notify_message(message?) {
    const notificationMSG = { message };
    this.message.template(
      this.template,
      {
        nzData: notificationMSG,
        nzPauseOnHover: true
      }
    );
  }
  connectLinkedin() {
    const modal = this.modalService.create<SocialPostDialogComponent, IModalData>({
      nzContent: SocialPostDialogComponent,
      nzData: {
        hideFacebook: true,
        hidePosting: true,
        hideLinkedIn: false,
        hideGoogle: true,
      },
      nzFooter: null
    });
    modal.afterClose.subscribe(response => {
      this.fetch_linkedin_autopost_settings();
    });
  }
  connectFacebook() {
    const modal = this.modalService.create<FacebookOnboardingDialogComponent, IModalData>({
      nzContent: FacebookOnboardingDialogComponent,
      nzData: {
        config: {
          autoConnect: true,
          isReAuth: this.isFacebookPageConnected,
        }
      },
      nzFooter: null,
      nzWidth: '50%',
    });
    modal.afterClose.subscribe(response => {
      this.checkCustomerConectedFacebookPage();
      this.fetch_instagram_autopost_settings();
    });
  }

  refreshIgName(){
    this.loadingIGName = true;
    this.instagramService.updateIgName().subscribe(res => {
      this.authService.refresh().subscribe( refresh => {
        this.loadingIGName = false;
        this.messageService.create('success', 'Instagram User name is updated!');
      });
    });
  }

  connectYoutube() {
    const modal = this.modalService.create<YoutubeOnboardingDialogComponent, IModalData>({
      nzContent: YoutubeOnboardingDialogComponent,
      nzData: {
        config: {
          autoConnect: true,
          isReAuth: this.isFacebookPageConnected,
        }
      },
      nzFooter: null,
      nzWidth: '50%',
    });
    modal.afterClose.subscribe(response => {
     this.checkCustomerConectedYoutube();
    });
  }
  connectTiktok() {
    const modal = this.modalService.create<TiktokOnboardingDialogComponent, IModalData>({
      nzContent: TiktokOnboardingDialogComponent,
      nzData: {
        config: {
          autoConnect: true,
          isReAuth: this.isTiktokConnected,
        }
      },
      nzFooter: null,
      nzWidth: '50%',
    });
    modal.afterClose.subscribe(response => {
      this.checkCustomerConectedTiktok();
    });
  }

  connectInstagram() {
    if (this.facebookPageIntegration) {
      this.instagramService.create({ provider: 'instagram' }).subscribe((c) => {
        this.notification.create(
          'success',
          'Connected',
          'Instagram successfully connected!'
        );
        this.fetch_instagram_autopost_settings();
      }, err => {
        // console.log(err)
        // this.message.create('error', 'Error', `Error creating Instagram connection. ${err.error}`);
      });
    } else {
      this.connectFacebook();
    }
  }

  connectGoogle() {
    const modal = this.modalService.create<GoogleOnboardingDialogComponent, IModalData>({
      nzContent: GoogleOnboardingDialogComponent,
      nzData: {
        config: {
          autoConnect: true
        }
      },
      nzFooter: null,
      nzWidth: '50%',
    });
    modal.afterClose.subscribe(response => {
      // console.log(response);
      this.checkCustomerConectedGoogleBusiness();
      // this.fetch_instagram_autopost_settings();
    });
  }

  upgradePlan() {
    const modal = this.modalService.create({
      nzContent: UpgradeDialogComponent,
      nzFooter: null
    });
  }
  fetch_tier() {
    this.authService.refresh()
      .subscribe(c => {
        if (c) {
          this.paidTier = c?.tier === 'ultra';
        }
      });
  }

  setSideMenu(menuItem) {
    this.selectedMenu = menuItem.key;
    this.selectedMenuItem = menuItem;
    if (this.settings) {
      this.filteredSettings = this.settings?.filter(s => menuItem.key === this.getSelectedItem(s.key));
    }
    this.location.replaceState(`/settings/${menuItem.key}`);
    this.breadcrumService.set_breadcrum();
  }
  save() {
    if (!this.customerForm.valid) {
      this.customerForm.checkForm();
      this.messageService?.remove();
      this.messageService?.create('error', 'You have invalid fields.');
      return;
    }
    this.submitCustomer();
  }

  saveRealtor() {
    if (!this.realtorForm.valid) {
      this.messageService?.remove();
      this.messageService?.create('error', 'You have invalid fields.');
      return;
    } else {
      this.customer.license_number = this.mlsRealtor.license_number;
      this.customer.brokerage = this.mlsRealtor.brokerage;
      this.submitCustomer();
    }
  }
  submitCustomer(success_message = 'Success! Profile saved.') {
    this.loading = true;
    this.customerService.update(this.customer).subscribe((response) => {
      if (response) {
        this.customer = response;
        this.authService.updateCurrentCustomer(response);
        this.messageService.create('success', success_message);
      }
      this.loading = false;
    },
      error => {
        console.error(error);
        this.loading = false;
      });
  }

  saveDataPoints() {
    const checkedData = {};
    this.dataPointKeys.forEach(k => {
      if (this.dataPoints[k].selected) {
        checkedData[k] = true;
      }
    });
    this.customerService.updateDataPoints(checkedData, this.dataPointsId)
      .subscribe(res => {
        this.messageService?.remove();
        this.messageService?.create('success', 'Data points saved successfully');
      })
  }
  toHuman(s) {
    return s
      .replace(/(^|[_-])([a-z])/g, (a, b, c) => c.toUpperCase())
      .replace(/([a-z])([A-Z])/g, (a, b, c) => `${b} ${c}`);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }

    this.dataPointKeys.forEach(key => {
      if (this.selectedDataPoints.indexOf(key) > -1) {
        this.dataPoints[key].selected = true;
      } else {
        this.dataPoints[key].selected = false;
      }
    });
    this.saveDataPoints();
  }
  async fetchCustomerProducts() {
    // if (this.showGMB) {
    //   return;
    // }
    this.loading = true;
    const params: Record<string, any> = {
      'per_page': 50,
    };

    this.productsService.productList(
      `customers/${this.customer?.id}/products`, params
    ).subscribe(res => {
      if (res) {
        const data = res.data;
        const googleProduct = data ? data?.find(p => p.abbreviation === 'GMB' && p.locked === false) : null;
        const linkedinProduct = data ? data?.find(p => p.abbreviation === 'LI' && p.locked === false) : null;
        const instagramProduct = data ? data?.find(p => p.abbreviation === 'IG' && p.locked === false) : null;
        const youtubeProduct = data ? data?.find(p => p.abbreviation === 'YT' && p.locked === false) : null;
        const tiktokProduct = data ? data?.find(p => p.abbreviation === 'TT' && p.locked === false) : null;

        if (googleProduct && googleProduct != null) {
          this.googleConnectedMydBusinessStatus = true;
          this.globalsService.setIsGMBAllowed(true);
        }
        if (linkedinProduct && linkedinProduct != null) {
          this.linkedinStatus = true;
        }
        if (instagramProduct && instagramProduct != null) {
          this.instagramStatus = true;
        }
        if (youtubeProduct && youtubeProduct != null) {
          this.youtubeStatus = true;
        }
        if (tiktokProduct && tiktokProduct != null) {
          this.tiktokStatus = true;
        }
        this.loading = false;
      }
    }, err => {
      this.loading = false;
    })

  }

  deleteIntegration(integration){
    const modal = this.modalService.create<GenericDialogComponent, GenericDialogConfig>({
      nzContent: GenericDialogComponent,
      nzData: {
        title: 'Confirm',
        message: 'Are you sure you want to delete this integration?',
        buttonLabel: 'Cancel',
        extraButtons: [
          {
            label: 'Confirm',
            value: true,
            color: 'warn'
          }
        ]
      },
      nzFooter: null
    });
    modal.afterClose.subscribe(response => {
      if (response === true) {
        this.loading = true;
        this.integrationsService.deleteIntegration(integration).subscribe(r => {
          this.checkCustomerConectedFacebookPage();
          this.checkCustomerConectedGoogleBusiness();
          this.checkCustomerConectedYoutube();
          this.checkCustomerConectedTiktok();
          this.fetch_linkedin_autopost_settings();
          this.fetch_instagram_autopost_settings();
          this.loading = false;

          this.messageService.create('success', `${this.getIntegrationName(integration)} has been successfully removed.`);
        });
      }
    });
  }

  createQrCode(){
    this.qrcodeService.create({slug: this.customer.slug}).subscribe( res => {
      this.messageService?.create('success', 'Slug saved successfully');
      this.customer.slug = res.slug
      this.customer.qrcode_url = res.image_url
    });
  }

  getSocialLinks(){
    this.sociallinksService.list().subscribe(res => {
      this.sociallinks = res;
    });
  }
  showSocialLinksForm() {
    const modal = this.modalService.create({
      nzContent: SocialLinksDialogComponent,
      nzData: {

      },
      nzFooter: null,
      nzWidth: '40%',
      nzCentered: true
    });
    modal.afterClose.subscribe(resp => {
      this.getSocialLinks()
    });
  }
  async download() {
    await this.downloaderService.save(this.customer.qrcode_url, `${this.customer.first_name} ZentapCard`);
  }

  getIntegrationName(integration){
    switch(integration){
      case this.facebookPageIntegration:
        return 'Facebook';
      case this.integration:
        return 'LinkedIn';
      case this.instagramIntegration:
        return 'Instagram';
      case this.googlePageIntegration:
        return 'Google';
      case this.youtubeIntegration:
        return 'Youtube';
      case this.tiktokIntegration:
        return 'Tiktok';
      default:
        return 'Integration';
    }
  }

  get limitMessage() {
    return `You already have selected 9 data points.`;
  }

  get minLimitMessage() {
    return `You should have minimum 9 selected data points`;
  }

  get isMinimum() {
    return this.selectedDataPoints.length <= 9;
  }
  get showGMB() {
    return this.globalsService.getIsGMBAllowed();
  }

  get isAdmin() {
    return !!this.authService.decodedUser?.aid;
  }
  goToLink(url){
    // Check if the URL starts with "http://" or "https://"
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      // If not, add "https://" to the URL
      url = 'https://' + url;
    }

    window.open(url, '_blank');
  }
  deleteSocialLink(link){
    this.sociallinksService.destroy(link).subscribe(res => {
      this.messageService.success('Social Link Removed!');
      this.getSocialLinks();
    });
  }
  handleSubmitSocialLink(link) {
    this.sociallinksService.create({platform: link.platform, link: link.data.url}).subscribe( res => {
      this.messageService.success('Social links added to your Zentap Card!');
    });
  }
  markYoutubeMessageAsShown(): void {
    this.customersYoutubeService.markYoutubeMessageAsShown(this.youtubeIntegration).subscribe();
  }

  onServiceAutopostSwitchChange(service: string, newState: boolean) {
    this.tempAutopostState[service] = newState;
    const action = newState ? 'enable' : 'disable';
    this.openAutopostModal(service, action);
  }

  openAutopostModal(service: string, action: 'enable' | 'disable') {
    this.modalService.confirm({
      nzTitle: `Confirm ${service.charAt(0).toUpperCase() + service.slice(1)} Auto Posting`,
      nzContent: `Are you sure you want to ${action} auto posting for your ${service.charAt(0).toUpperCase() + service.slice(1)} Page?`,
      nzOnOk: () => this.confirmAutopost(service, action),
      nzOnCancel: () => this.cancelAutopost(service)
    });
  }

  confirmAutopost(service: string, action: 'enable' | 'disable') {
    const newState = action === 'enable';

    switch (service) {
      case 'facebook':
        this.toggleFacebookPageSetting(newState);
        break;
      case 'instagram':
        this.toggleInstagramSetting(newState);
        break;
      case 'youtube':
        this.toggleYoutubeSetting(newState);
        break;
      case 'linkedIn':
        this.toggleLinkedInSetting(newState);
        break;
      case 'tiktok':
        this.toggleTiktokSetting(newState);
        break;
      case 'google':
        this.toggleGoogleMyBusinessSetting(newState);
        break;
      default:
        break;
    }
  }

  cancelAutopost(service: string) {
    switch (service) {
      case 'facebook':
        this.facebookPageAutopost = !this.tempAutopostState[service];
        break;
      case 'instagram':
        this.instagramAutopost = !this.tempAutopostState[service];
        break;
      case 'youtube':
        this.youtubeAutopost = !this.tempAutopostState[service];
        break;
      case 'linkedIn':
        this.linkedinAutopost = !this.tempAutopostState[service];
        break;
      case 'tiktok':
        this.tiktokAutopost = !this.tempAutopostState[service];
        break;
      case 'google':
        this.googleBusinessAutopost = !this.tempAutopostState[service];
      default:
        break;
    }
  }
  
}
